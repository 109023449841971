<template>
  <div v-if="!hasPermission('List Place')">Você não tem permissão para acessar esta página!</div>
  <div v-else>
      <Toolbar />
      <div class="container-xxl">
        <div class="card">
          <div class="card-body py-4">
            <div class="d-flex flex-wrap flex-sm-nowrap mb-6">
              <div class="flex-grow-1">
                <div class="d-flex justify-content-between align-items-start flex-wrap mb-2">
                  <div class="d-flex flex-column">
                    <div class="d-flex align-items-center mb-1">
                      <a href="#" class="text-gray-800 text-hover-primary fs-2 fw-bolder me-3">
                        {{ place.place_name }}
                      </a>
                      <span class="badge me-auto" :class="place.active ? 'badge-light-success' : 'badge-light-danger'">
                      {{ place.active ? 'Ativo' : 'Inativo' }}
                    </span>
                    </div>
                    <div class="d-flex flex-wrap fw-bold mb-4 fs-5 text-gray-400">
                      #{{ place.id_place }} {{ place.place_address }}, {{ place.place_addr_numer }}
                    </div>
                  </div>
                  <div class="d-flex mb-4"></div>
                </div>
                <div class="d-flex flex-wrap justify-content-start">
                  <div class="d-flex flex-wrap">
                    <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                      <div class="d-flex align-items-center">
                        <div class="fs-4 fw-bolder">
                          {{ date(place.created_at).format('DD/MM/YYYY h:mm:ss') }}
                        </div>
                      </div>
                      <div class="fw-bold fs-6 text-gray-400">Data de Cadastro</div>
                    </div>
                    <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                      <div class="d-flex align-items-center">
                        <div class="fs-4 fw-bolder counted" data-kt-countup="true"
                             :data-kt-countup-value="place.place_latitude">
                          {{ place.place_latitude ? formatNumber(place.place_latitude) : '' }}
                        </div>
                      </div>
                      <div class="fw-bold fs-6 text-gray-400">Latitude</div>
                    </div>
                    <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                      <div class="d-flex align-items-center">
                        <div class="fs-4 fw-bolder counted" data-kt-countup="true"
                             :data-kt-countup-value="place.place_longitude">
                          {{ place.place_longitude ? formatNumber(place.place_longitude) : '' }}
                        </div>
                      </div>
                      <div class="fw-bold fs-6 text-gray-400">Longitude</div>
                    </div>
                  </div>
                  <div class="symbol-group symbol-hover mb-3">
                    <div class="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip"
                         :title="user.user_name" v-for="user in users" :data-bs-original-title="user.user_name">
                    <span class="symbol-label bg-ubbi text-inverse-warning fw-bolder">
                      {{ user.user_name.charAt(0).toUpperCase() }}
                    </span>
                    </div>

                    <router-link to="/users" class="symbol symbol-35px symbol-circle">
                    <span class="symbol-label bg-dark text-inverse-dark fs-8 fw-bolder" data-bs-toggle="tooltip"
                          data-bs-trigger="hover" title="Ver todos os usuários" data-bs-original-title="Ver usuários">
                      +
                    </span>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <br>

        <div class="card">
          <div class="card-header">
            <div class="card-title fs-3 fw-bolder">Dados Cadastrais</div>
          </div>
          <form id="kt_project_settings_form" class="form" method="post" @submit.prevent="update">
            <div class="card-body p-9">
              <div class="row mb-5">
                <div class="col-xl-1">
                  <div class="fs-6 fw-bold mt-2 mb-3">Logo</div>
                </div>
                <div class="col-lg-8">
                  <div class="image-input image-input-circle" data-kt-image-input="true"
                       :class="place.place_logo ? '' : 'image-input-empty'"
                       :style="{backgroundImage: place.place_logo ? '' : `url(${require('@/assets/images/no-photo.png')})`}">

                    <div class="image-input-wrapper w-125px h-125px" v-if="place.place_logo"
                         :style="{backgroundImage: `url(${place.place_logo})`}">
                    </div>

                    <div class="image-input-wrapper w-125px h-125px" style="background-image: none;" v-else></div>

                    <label class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                           data-kt-image-input-action="change" data-bs-toggle="tooltip" title=""
                           data-bs-original-title="Alterar Foto">
                      <i class="bi bi-pencil-fill fs-7"></i>
                      <input type="file" ref="photo" name="photo" id="photo" accept=".png, .jpg, .jpeg"
                             @change="handleFileChange">
                      <input type="hidden" name="avatar_remove">
                    </label>

                    <span class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                          data-kt-image-input-action="cancel" data-bs-toggle="tooltip" title=""
                          data-bs-original-title="Cancelar" @click="cancelImage">
                <i class="bi bi-x fs-2"></i>
            </span>

                    <span class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                          data-kt-image-input-action="remove" data-bs-toggle="tooltip" title=""
                          data-bs-original-title="Remover foto" @click="removeImage">
                <i class="bi bi-x fs-2"></i>
            </span>
                  </div>
                  <div class="form-text">Os formatos permitidos são: png, jpg, jpeg.</div>
                </div>

              </div>
              <div class="row mb-8">
                <div class="col-md-4">
                  <div class="mb-10">
                    <label for="place_name" class="required form-label">Nome</label>
                    <input type="text" class="form-control form-control-solid" id="place_name"
                           :class="errors.place_name ? 'is-invalid' : ''"
                           name="place_name" v-model="place.place_name"/>
                    <div class="fv-plugins-message-container invalid-feedback" v-if="errors.place_name">
                      {{ errors.place_name.join('\n') }}
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="mb-10">
                    <label for="place_type" class="required form-label">Tipo</label>
                    <select class="form-control form-control-solid" id="place_type"
                            :class="errors.place_type ? 'is-invalid' : ''"
                            v-model="place.place_type" name="place_type">
                      <option :value="key" v-for="(type, key) in placeTypes">
                        {{ type }}
                      </option>
                    </select>
                    <div class="fv-plugins-message-container invalid-feedback" v-if="errors.place_type">
                      {{ errors.place_type.join('\n') }}
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="mb-10">
                    <label for="place_phone" class="required form-label">Telefone</label>
                    <input type="text" class="form-control form-control-solid" id="place_phone"
                           :class="errors.place_phone ? 'is-invalid' : ''"
                           name="place_phone" v-model="place.place_phone"/>
                    <div class="fv-plugins-message-container invalid-feedback" v-if="errors.place_phone">
                      {{ errors.place_phone.join('\n') }}
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="mb-10">
                    <label for="place_domain" class="required form-label">Domínio(WebRTC)</label>
                    <input disabled type="text" class="form-control form-control-solid" id="place_domain"
                           :class="errors.place_domain ? 'is-invalid' : ''"
                           name="place_domain" v-model="place.place_domain"/>
                    <div class="fv-plugins-message-container invalid-feedback" v-if="errors.place_domain">
                      {{ errors.place_domain.join('\n') }}
                    </div>
                  </div>
                </div>

                <div class="col-md-2">
                  <label class="form-label fw-bolder text-dark fs-6">
                    CEP <span class="text-danger">*</span>
                  </label>
                  <input class="form-control form-control-lg form-control-solid" type="text" required
                         :class="errors.place_zip_code ? 'is-invalid' : ''" v-model="place.place_zip_code"
                         v-mask="'#####-###'" placeholder="Ex: 00000-000"
                         name="place_zip_code" autocomplete="off" @blur="getZipCode"/>

                  <div class="fv-plugins-message-container invalid-feedback" v-if="errors.place_zip_code">
                    {{ errors.place_zip_code.join('\n') }}
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="mb-10">
                    <label for="place_address" class="required form-label">Endereço</label>
                    <input type="text" class="form-control form-control-solid" id="place_address"
                           :class="errors.place_address ? 'is-invalid' : ''"
                           name="place_address" v-model="place.place_address"/>
                  </div>
                </div>
                <div class="col-md-1">
                  <div class="mb-10">
                    <label for="place_addr_numer" class="required form-label">Número</label>
                    <input type="text" class="form-control form-control-solid" id="place_addr_numer"
                           :class="errors.place_addr_numer ? 'is-invalid' : ''"
                           name="place_addr_numer" v-model="place.place_addr_numer"/>
                    <div class="fv-plugins-message-container invalid-feedback" v-if="errors.place_addr_numer">
                      {{ errors.place_addr_numer.join('\n') }}
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="mb-10">
                    <label for="place_neighborhood" class="required form-label">Bairro</label>
                    <input type="text" class="form-control form-control-solid" id="place_neighborhood"
                           :class="errors.place_neighborhood ? 'is-invalid' : ''"
                           name="place_neighborhood" v-model="place.place_neighborhood"/>
                    <div class="fv-plugins-message-container invalid-feedback" v-if="errors.place_neighborhood">
                      {{ errors.place_neighborhood.join('\n') }}
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="mb-10">
                    <label for="place_addr_compl" class="form-label">Complemento</label>
                    <input type="text" class="form-control form-control-solid" id="place_addr_compl"
                           :class="errors.place_addr_compl ? 'is-invalid' : ''"
                           name="place_addr_compl" v-model="place.place_addr_compl"/>
                    <div class="fv-plugins-message-container invalid-feedback" v-if="errors.place_addr_compl">
                      {{ errors.place_addr_compl.join('\n') }}
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="mb-10">
                    <label for="id_state" class="required form-label">Estado</label>
                    <select type="text" class="form-control form-control-solid" id="id_state"
                            @change="getCitiesFromState($event.target.value)"
                            :class="errors.id_state ? 'is-invalid' : ''"
                            name="state" v-model="place.id_state">
                      <option :value="state.id_state" v-for="state in states">
                        {{ state.state_name }}
                      </option>
                    </select>
                    <div class="fv-plugins-message-container invalid-feedback" v-if="errors.id_state">
                      {{ errors.id_state.join('\n') }}
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="mb-10">
                    <label for="id_city" class="required form-label">Cidade</label>
                    <select type="text" class="form-control form-control-solid" id="id_city"
                            :class="errors.id_city ? 'is-invalid' : ''"
                            name="state" v-model="place.id_city">
                      <option :value="city.id_city" v-for="city in citiesFromState">
                        {{ city.city_name }}
                      </option>
                    </select>
                    <div class="fv-plugins-message-container invalid-feedback" v-if="errors.id_city">
                      {{ errors.id_city.join('\n') }}
                    </div>
                  </div>
                </div>


                <div class="col-md-1">
                  <div class="mb-10">
                    <label for="status" class="required form-label">Status</label>
                    <div class="form-check form-switch form-check-custom form-check-solid">
                      <input class="form-check-input" type="checkbox" v-model="place.active" id="status" name="status">
                      <label class="form-check-label fw-bold text-gray-400 ms-3" for="status">Ativo</label>
                    </div>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="mb-10">
                    <label for="status" class="required form-label">Replicar Disponibilidade</label>
                    <div class="form-check form-switch form-check-custom form-check-solid">
                      <input class="form-check-input" type="checkbox" v-model="place.unify_avail_default" id="status" name="status">
                      <label class="form-check-label fw-bold text-gray-400 ms-3" for="status">Replicar disponibilidades por padrão</label>
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div
                      class="row fv-row mb-7 rounded border-gray-300 border-1 border-gray-300 border-dashed px-7 py-3 mb-6">
                    <div class="col-xl-4">
                      <label class="form-label fw-bolder text-dark fs-6">
                        Tipo de Chave PIX<span class="text-danger">*</span>
                      </label>
                      <Dropdown v-model="place.place_pix_key_type" :options="pix_key_types"
                                optionLabel="name" optionValue="id"
                                class="form-control form-control-solid"
                                :class="errors.place_pix_key_type ? 'is-invalid' : ''"
                                @change="maskPIXKey($event.value)"
                                :filter="true" placeholder="Tipo de Chave" style="display: flex;"/>
                      <div class="fv-plugins-message-container invalid-feedback" v-if="errors.place_pix_key_type">
                        {{ errors.place_pix_key_type.join('\n') }}
                      </div>
                    </div>
                    <div class="col-xl-8">
                      <label class="form-label fw-bolder text-dark fs-6">
                        Chave PIX <span class="text-danger">*</span>
                      </label>
                      <input v-if="pix_key_mask" class="form-control form-control-lg form-control-solid" type="text"
                             required
                             v-mask="pix_key_mask"
                             v-model="place.place_pix_key"
                             placeholder="Informe sua Chave PIX para recebimentos"
                             name="place_pix_key" autocomplete="off"/>

                      <input v-if="!pix_key_mask" class="form-control form-control-lg form-control-solid" type="text"
                             required
                             v-model="place.place_pix_key"
                             :class="errors.place_pix_key ? 'is-invalid' : ''"
                             placeholder="Informe sua Chave PIX para recebimentos"
                             name="place_pix_key" autocomplete="off"/>
                      <div class="fv-plugins-message-container invalid-feedback" v-if="errors.place_pix_key">
                        {{ errors.place_pix_key.join('\n') }}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="mb-10">
                    <label for="id_city" class="required form-label">Termo da teleconsulta</label>
                    <textarea rows="3" class="form-control form-control-solid"
                              placeholder="Termo da teleconsulta" v-model="place.term_teleconsultation"></textarea>
                  </div>
                </div>


              </div>
            </div>
            <div class="card-footer d-flex justify-content-end py-6 px-9">
              <button v-if="hasPermission('Store Place')" type="submit" class="btn btn-primary"
                      id="kt_project_settings_submit">Salvar
              </button>
            </div>
          </form>
        </div>
      </div>
  </div>

</template>

<script>
import http from "../../helpers/http";
import {mask} from "vue-the-mask";
import strMask from "../../helpers/strMask";
import Alert from "../../helpers/alert";
import Dropdown from 'primevue/dropdown';
import Permission from "../../helpers/permission";
import Toolbar from "../../components/layouts/auth/Toolbar.vue";

export default {
  name: "Place",
  directives: {
    mask,
  },
  components: {
    Dropdown,
      Toolbar,
  },
  data() {
    return {
      errors: [],
      submitted: false,
      placeTypes: [],
      states: [],
      citiesFromState: [],
      users: [],
      place: {},
      pix_key_types: [],
      pix_key_mask: '',
    }
  },
  computed: {
    currentPlace() {
      return this.$store.state.auth.place;
    }
  },
  created() {
    this.getUsersFromPlace();
    this.getStates();
    this.getPlaceTypes();
    this.getPixKeyTypes();
  },
  async mounted() {

    if (this.currentPlace.id_state) {
      await this.getCitiesFromState(this.currentPlace.id_state).then((response) => {
        this.citiesFromState = response.data;
      });
    }

    this.maskPIXKey(this.currentPlace.place_pix_key_type);

    this.place = {
      "id_place": this.currentPlace.id_place,
      "place_name": this.currentPlace.place_name,
      "place_address": this.currentPlace.place_address,
      "place_addr_numer": this.currentPlace.place_addr_numer,
      "place_addr_compl": this.currentPlace.place_addr_compl,
      "place_neighborhood": this.currentPlace.place_neighborhood,
      "place_phone": this.currentPlace.place_phone,
      "place_logo": this.currentPlace.place_logo,
      "place_latitude": this.currentPlace.place_latitude,
      "place_longitude": this.currentPlace.place_longitude,
      "id_city": this.currentPlace.id_city,
      "active": this.currentPlace.active,
      "term_teleconsultation": this.currentPlace.term_teleconsultation,
      "place_type": this.currentPlace.place_type,
      "place_domain": this.currentPlace.place_domain,
      "id_add_policy": this.currentPlace.id_add_policy,
      "created_at": this.currentPlace.created_at,
      "id_state": this.currentPlace.id_state,
      "place_zip_code": this.currentPlace.place_zip_code,
      "place_pix_key_type": this.currentPlace.place_pix_key_type,
      "place_pix_key": this.currentPlace.place_pix_key,
      "unify_avail_default": this.currentPlace.unify_avail_default,
    };

    KTImageInput.createInstances();
  },
  methods: {
    date: moment,
    maskIt(value, mask) {
      return strMask.it(value, mask);
    },
    getUsersFromPlace() {
      http.get(`/places/${this.currentPlace.id_place}/users`).then((response) => {
        this.users = response.data;
      });
    },
    getPlaceTypes() {
      http.get(`/place-types`).then((response) => {
        this.placeTypes = response.data;
      });
    },
    getStates() {
      http.get(`/states`).then((response) => {
        this.states = response.data;
      });
    },
    getCitiesFromState(stateId) {
      return http.get(`/states/${stateId}/cities`).then((response) => {
        this.citiesFromState = response.data;

        return response;
      });
    },
    getPixKeyTypes() {
      http.get(`/pixkeytypes`).then((response) => {
        this.pix_key_types = response.data;
      });
    },
    maskPIXKey(keyPixType) {
      switch (keyPixType) {
        case 'CPF':
          this.pix_key_mask = '###.###.###-##';
          break;
        case 'CNPJ':
          this.pix_key_mask = '##.###.###/####-##';
          break;
        case 'PHONE':
          this.pix_key_mask = '(##) #####-####';
          break;
        case 'EMAIL':
          this.pix_key_mask = ''
          break;
        default:
          this.pix_key_mask = '';
          break;
      }
    },
    update() {
      http.put(`/places/${this.place.id_place}`, this.place).then((response) => {
        Alert.toastSuccess("Local de atendimento atualizado com sucesso.");
        this.errors = [];
        this.$store.commit('auth/setPlace', response.data);
      }).catch((error) => {
        if (error.response.data?.errors) {
          this.errors = error.response.data.errors;
        } else {
          Alert.toastError(error.response.data)
        }
      });
    },
    handleFileChange() {
      const file = this.$refs.photo.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.place.place_logo = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    cancelImage() {
      this.$refs.photo.value = null;
      this.place.place_logo = null;
    },
    removeImage() {
      this.place.place_logo = null;
    },
    formatNumber(value) {
      return parseFloat(value).toFixed(6);
    },
    getZipCode() {
      http.get(`zipcode/` + this.place.place_zip_code).then((response) => {
        console.log(response.data);
        this.place.place_address = response.data.address;
        this.place.place_neighborhood = response.data.neighborhood;
        this.place.id_state = response.data.state?.id_state;
        this.getCitiesFromState(this.place.id_state);
        this.place.id_city = response.data.city?.id_city;
      }).catch((e) => {
        console.log(e.response)
      });
    },
    hasPermission(permission) {
      return Permission.has(permission);
    },
  },
}
</script>

<style scoped>

</style>