<template>

  <div class="alert alert-danger" role="alert" v-if="messageError">
    {{ messageError }}
  </div>

  <div class="stepper stepper-links d-flex flex-column" id="kt_register_stepper">
    <div class="stepper-nav py-5">
      <div class="stepper-item current" data-kt-stepper-element="nav" @click="stepper.goTo(1);">
        <h3 class="stepper-title">Dados</h3>
      </div>
      <div class="stepper-item" data-kt-stepper-element="nav" @click="stepper.goTo(2);">
        <h3 class="stepper-title">Local</h3>
      </div>
      <div class="stepper-item" data-kt-stepper-element="nav" @click="stepper.goTo(3);">
        <h3 class="stepper-title">Especialidades</h3>
      </div>
    </div>

    <form class="form w-100" novalidate="novalidate" id="kt_sign_up_form" method="post" @submit.prevent="onSubmit">

      <div class="current" data-kt-stepper-element="content">
        <div class="container">
          <div class="row fv-row mb-7">
            <div class="col-xl-6">
              <label class="form-label fw-bolder text-dark fs-6">
                Nome <span class="text-danger">*</span>
              </label>
              <input class="form-control form-control-lg form-control-solid" type="text" required
                     :class="errors.prof_name ? 'is-invalid' : ''" v-model="register.prof_name"
                     placeholder="Informe o seu nome" name="prof_name" autocomplete="off"/>

              <div class="fv-plugins-message-container invalid-feedback" v-if="errors.prof_name">
                {{ errors.prof_name.join('\n') }}
              </div>
            </div>
            <div class="col-xl-6">
              <label class="form-label fw-bolder text-dark fs-6">
                CPF <span class="text-danger">*</span>
              </label>
              <input class="form-control form-control-lg form-control-solid" type="text" required
                     :class="errors.prof_cpf ? 'is-invalid' : ''" v-model="register.prof_cpf"
                     v-mask="'###.###.###-##'" placeholder="Informe o seu CPF"
                     name="prof_cpf" autocomplete="off"/>

              <div class="fv-plugins-message-container invalid-feedback" v-if="errors.prof_cpf">
                {{ errors.prof_cpf.join('\n') }}
              </div>
            </div>
          </div>
          <div class="row fv-row mb-7">
            <div class="col-xl-6">
              <label class="form-label fw-bolder text-dark fs-6">
                E-mail <span class="text-danger">*</span>
              </label>
              <input class="form-control form-control-lg form-control-solid" type="email" required
                     :class="errors.prof_email ? 'is-invalid' : ''" v-model="register.prof_email"
                     :disabled="this.registerParam?.prof_email"
                     placeholder="Informe o seu e-mail" name="prof_email" autocomplete="off"/>

              <div class="fv-plugins-message-container invalid-feedback" v-if="errors.prof_email">
                {{ errors.prof_email.join('\n') }}
              </div>
            </div>
            <div class="col-xl-6">
              <label class="form-label fw-bolder text-dark fs-6">
                Telefone <span class="text-danger">*</span>
              </label>
              <input class="form-control form-control-lg form-control-solid" type="text" required
                     :class="errors.prof_phone ? 'is-invalid' : ''" v-model="register.prof_phone"
                     v-mask="['(##) ####-####', '(##) #####-####']"
                     placeholder="Informe o seu telefone" name="prof_phone" autocomplete="off"/>

              <div class="fv-plugins-message-container invalid-feedback" v-if="errors.prof_phone">
                {{ errors.prof_phone.join('\n') }}
              </div>
            </div>
          </div>

          <div class="mb-10 fv-row row" data-kt-password-meter="true">
            <div class="col-xl-6">
              <div class="mb-1">
                <label class="form-label fw-bolder text-dark fs-6">
                  Senha <span class="text-danger">*</span>
                </label>
                <div class="position-relative mb-3">
                  <input class="form-control form-control-lg form-control-solid" type="password" required
                         :class="errors.user_password ? 'is-invalid' : ''" v-model="register.user_password"
                         placeholder="Informe a sua senha" name="user_password" autocomplete="off"/>

                  <div class="fv-plugins-message-container invalid-feedback" v-if="errors.user_password">
                    {{ errors.user_password.join('\n') }}
                  </div>

                  <span class="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
                        data-kt-password-meter-control="visibility">
                      <i class="bi bi-eye-slash fs-2"></i>
                      <i class="bi bi-eye fs-2 d-none"></i>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-xl-6">
              <label class="form-label fw-bolder text-dark fs-6">
                Confirme a senha <span class="text-danger">*</span>
              </label>
              <input class="form-control form-control-lg form-control-solid" type="password" required
                     :class="errors.user_password ? 'is-invalid' : ''" v-model="register.user_password_confirmation"
                     placeholder="Confirme sua senha" name="user_password_confirmation" autocomplete="off"/>

              <div class="fv-plugins-message-container invalid-feedback" v-if="errors.user_password">
                {{ errors.user_password.join('\n') }}
              </div>
            </div>

            <div class="d-none align-items-center mb-3" data-kt-password-meter-control="highlight">
              <div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
              <div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
              <div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
              <div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px"></div>
            </div>

          </div>
          <div class="fv-row mb-10">
            <label class="form-check form-check-custom form-check-solid form-check-inline">
              <span class="form-check-label fw-bold text-gray-700 fs-6">
                Ao clicar em continuar você concorda com os
                <a target="_blank" href="https://policies.ubbimed.com.br/terms.php" class="ms-1 link-primary">
                  Termos e condições
                </a> da Ubbimed.
                 <span class="text-danger">*</span>
              </span>
            </label>
          </div>
        </div>
      </div>

      <div data-kt-stepper-element="content">
        <div class="container">
          <div class="row fv-row mb-7">
            <div class="col-xl-12">
              <label class="form-label fw-bolder text-dark fs-6" for="place_name">
                Nome do Local de Atendimento Presencial <span class="text-danger">*</span>
              </label>
              <input class="form-control form-control-lg form-control-solid" type="text" required
                     :class="errors.place_name ? 'is-invalid' : ''" v-model="register.place_name"
                     id="place_name"
                     placeholder="Ex. Clínica Dr. José" name="place_name" autocomplete="off"/>

              <div class="fv-plugins-message-container invalid-feedback" v-if="errors.place_name">
                {{ errors.place_name.join('\n') }}
              </div>
            </div>
          </div>

          <div class="row fv-row mb-7">
            <div class="col-xl-4">
              <label class="form-label fw-bolder text-dark fs-6">
                Tipo de Chave PIX<span class="text-danger">*</span>
              </label>
              <Dropdown v-model="register.place_pix_key_type" :options="pix_key_types"
                        optionLabel="name" optionValue="id"
                        class="form-control form-control-solid"
                        :class="errors.place_pix_key_type ? 'is-invalid' : ''"
                        @change="maskPIXKey($event.value)"
                        :filter="true" placeholder="Tipo de Chave" style="display: flex;"/>
              <div class="fv-plugins-message-container invalid-feedback" v-if="errors.place_pix_key_type">
                {{ errors.place_pix_key_type.join('\n') }}
              </div>
            </div>
            <div class="col-xl-8">
              <label class="form-label fw-bolder text-dark fs-6">
                Chave PIX <span class="text-danger">*</span>
              </label>
              <input v-if="pix_key_mask" class="form-control form-control-lg form-control-solid" type="text" required
                     :class="errors.place_pix_key ? 'is-invalid' : ''"
                     v-model="register.place_pix_key"
                     v-mask="pix_key_mask"
                     placeholder="Informe sua Chave PIX para recebimentos"
                     name="place_pix_key" autocomplete="off"/>

              <input v-if="!pix_key_mask" class="form-control form-control-lg form-control-solid" type="text" required
                     :class="errors.place_pix_key ? 'is-invalid' : ''" v-model="register.place_pix_key"
                     placeholder="Informe sua Chave PIX para recebimentos"
                     name="place_pix_key" autocomplete="off"/>
              <div class="fv-plugins-message-container invalid-feedback" v-if="errors.place_pix_key">
                {{ errors.place_pix_key.join('\n') }}
              </div>
            </div>
          </div>

          <div class="col-xl-4">
            <label class="form-label fw-bolder text-dark fs-6">
              CEP <span class="text-danger">*</span>
            </label>
            <input class="form-control form-control-lg form-control-solid" type="text" required
                   :class="errors.place_zip_code ? 'is-invalid' : ''" v-model="register.place_zip_code"
                   v-mask="'#####-###'" placeholder="Ex: 00000-000"
                   name="place_zip_code" autocomplete="off" @blur="getZipCode"/>

            <div class="fv-plugins-message-container invalid-feedback" v-if="errors.place_zip_code">
              {{ errors.place_zip_code.join('\n') }}
            </div>
          </div>

          <div class="row fv-row mb-7">
            <div class="col-xl-8">
              <label class="form-label fw-bolder text-dark fs-6" for="place_address">
                Endereço <span class="text-danger">*</span>
              </label>
              <input class="form-control form-control-lg form-control-solid" type="text" required
                     :class="errors.place_address ? 'is-invalid' : ''" v-model="register.place_address"
                     id="place_address"
                     placeholder="Ex. Rua 123" name="place_address" autocomplete="off"/>

              <div class="fv-plugins-message-container invalid-feedback" v-if="errors.place_address">
                {{ errors.place_address.join('\n') }}
              </div>
            </div>
            <div class="col-xl-4">
              <label class="form-label fw-bolder text-dark fs-6" for="place_addr_numer">
                Número <span class="text-danger">*</span>
              </label>
              <input class="form-control form-control-lg form-control-solid" type="text" required
                     :class="errors.place_addr_numer ? 'is-invalid' : ''" v-model="register.place_addr_numer"
                     id="place_addr_numer"
                     placeholder="Ex. 255" name="place_addr_numer" autocomplete="off"/>

              <div class="fv-plugins-message-container invalid-feedback" v-if="errors.place_addr_numer">
                {{ errors.place_addr_numer.join('\n') }}
              </div>
            </div>
          </div>

          <div class="row fv-row mb-7">
            <div class="col-xl-6">
              <label class="form-label fw-bolder text-dark fs-6" for="place_neighborhood">
                Bairro <span class="text-danger">*</span>
              </label>
              <input class="form-control form-control-lg form-control-solid" type="text" required
                     :class="errors.place_neighborhood ? 'is-invalid' : ''" v-model="register.place_neighborhood"
                     id="place_neighborhood"
                     placeholder="Ex. Centro" name="place_neighborhood" autocomplete="off"/>

              <div class="fv-plugins-message-container invalid-feedback" v-if="errors.place_neighborhood">
                {{ errors.place_neighborhood.join('\n') }}
              </div>
            </div>
            <div class="col-xl-6">
              <label class="form-label fw-bolder text-dark fs-6" for="place_addr_compl">
                Complemento
              </label>
              <input class="form-control form-control-lg form-control-solid" type="text"
                     :class="errors.place_addr_compl ? 'is-invalid' : ''" v-model="register.place_addr_compl"
                     id="place_addr_compl"
                     placeholder="Ex. Apt 10, Andar 9" name="place_addr_compl" autocomplete="off"/>

              <div class="fv-plugins-message-container invalid-feedback" v-if="errors.place_addr_compl">
                {{ errors.place_addr_compl.join('\n') }}
              </div>
            </div>
          </div>

          <div class="row fv-row mb-7">
            <div class="col-xl-6">
              <label class="form-label fw-bolder text-dark fs-6">
                Estado <span class="text-danger">*</span>
              </label><br>
              <Dropdown v-model="register.id_state" :options="states" optionLabel="state_name"
                     class="form-control form-control-solid" optionValue="id_state"
                     :class="errors.id_state ? 'is-invalid' : ''"
                     @change="getCitiesFromState($event.value)"
                     :filter="true" placeholder="Selecione o estado" style="display: flex;"/>
              <div class="fv-plugins-message-container invalid-feedback" v-if="errors.id_state">
                {{ errors.id_state.join('\n') }}
              </div>
            </div>

            <div class="col-xl-6">
              <label class="form-label fw-bolder text-dark fs-6">
                Cidade <span class="text-danger">*</span>
              </label><br>
              <Dropdown v-model="register.id_city" :options="citiesFromState" optionLabel="city_name"
                        class="form-control form-control-solid" optionValue="id_city"
                        :class="errors.id_city ? 'is-invalid' : ''"
                        :filter="true" placeholder="Selecione a cidade" style="display: flex;"/>

              <div class="fv-plugins-message-container invalid-feedback" v-if="errors.id_city">
                {{ errors.id_city.join('\n') }}
              </div>
            </div>

          </div>

        </div>
      </div>

      <div data-kt-stepper-element="content">
        <div class="container">
          <div class="fv-row mb-5">
            <div class="row">
              <div class="col-xl-6">
                <div class="card card-flush h-xl-100">
                  <div class="card-body">
                    <h3 class="card-title align-items-start flex-column">
                      <span class="card-label fw-bolder text-dark">Área</span>
                    </h3>
                    <div class="hover-scroll-overlay-y pe-6 me-n6" style="height: 415px">
                      <div class="rounded border-gray-300 border-1 border-gray-300 border-dashed px-7 py-3 mb-6"
                           style="cursor: pointer;" v-for="kind in kinds"
                           @click="loadSpecialtiesFromKind(kind.id_kind)">
                        <div class="d-flex flex-stack mb-3">
                          <div class="me-3">
                            {{ kind.kind_name }}
                            <span class="badge badge-light-info">
                              {{ kind.kind_acronym }}
                            </span>
                          </div>
                        </div>
                        <span class="text-gray-400 fw-bolder"></span>
                        <div class="d-flex flex-stack" v-if="register.kinds.includes(kind.id_kind)">
                          <span class="badge badge-light-success">
                            Selecionado
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-xl-6">
                <div class="card card-flush h-xl-100">
                  <div class="card-body">
                    <h3 class="card-title align-items-start flex-column">
                      <span class="card-label fw-bolder text-dark">Especialidade</span>

                      <input type="text" v-model="searchSpec"
                             class="form-control form-control-sm form-control-solid mt-5 mb-5"
                             placeholder="Pesquisar ...">
                    </h3>
                    <div class="hover-scroll-overlay-y pe-6 me-n6" id="specialties"
                         style="height: 415px">
                      <div class="rounded border-gray-300 border-1 border-gray-300 border-dashed px-7 py-3 mb-6"
                           style="cursor: pointer;" v-for="specialty in filteredSpecialties"
                           @click="selectSpecialty(specialty.id_spec)">
                        <div class="d-flex flex-stack mb-3">
                          <div class="me-3">
                            {{ specialty.spec_name }}
                          </div>
                        </div>
                        <span class="text-gray-400 fw-bolder"></span>
                        <div class="d-flex flex-stack" v-if="register.specialties.includes(specialty.id_spec)">
                          <span class="badge badge-light-success">
                            Selecionado
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div class="d-flex flex-stack pt-15">
        <div class="mr-2">
          <button type="button" class="btn btn-lg btn-light-primary me-3" data-kt-stepper-action="previous">
            <span class="svg-icon svg-icon-4 me-1">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <rect opacity="0.5" x="6" y="11" width="13" height="2" rx="1" fill="black"/>
                <path
                    d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z"
                    fill="black"/>
              </svg>
            </span>
            Voltar
          </button>
        </div>

        <div>
          <button type="submit" class="btn btn-lg btn-primary me-3" data-kt-stepper-action="submit">
            <span class="indicator-label">Cadastrar
              <span class="svg-icon svg-icon-3 ms-2 me-0">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)"
                        fill="black"/>
                  <path
                      d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                      fill="black"/>
                </svg>
              </span>
            </span>
            <span class="indicator-progress">Por favor aguarde...
              <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          </button>

          <button type="button" class="btn btn-lg btn-primary" data-kt-stepper-action="next">
            Continuar
            <span class="svg-icon svg-icon-4 ms-1 me-0">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)"
                      fill="black"/>
                <path
                    d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                    fill="black"/>
              </svg>
            </span>
          </button>

        </div>
      </div>
    </form>

  </div>
</template>

<script>
import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primevue/resources/themes/bootstrap4-light-blue/theme.css';
import 'primeicons/primeicons.css';
import http from "../../helpers/http";
import Alert from "../../helpers/alert";
import {mask} from "vue-the-mask";
import Dropdown from 'primevue/dropdown';

export default {
  name: "StepperRegister",
  props: {
    registerParam: Object,
  },
  directives: {
    mask,
  },
  components: {
    Dropdown
  },
  data() {
    return {
      submitted: false,
      stepper: null,
      searchSpec: '',
      searchInsurance: '',
      errors: [],
      messageError: '',
      kinds: [],
      paymentMethods: [],
      specialties: [],
      insurances: [],
      states: [],
      citiesFromState: [],
      pix_key_types: [],
      pix_key_mask: '',
      register: {
        prof_name: '',
        prof_email: '',
        user_password: '',
        user_password_confirmation: '',
        prof_cpf: '',
        prof_phone: '',
        term_conditions: true,
        place_name: '',
        place_address: '',
        place_addr_numer: '',
        place_addr_compl: '',
        place_neighborhood: '',
        id_state: '',
        id_city: '',
        specialties: [],
        kinds: [],
        prof_council: '',
        payment_methods: [],
        insurances: [],
        place_zip_code: '',
        place_pix_key: '',
        place_pix_key_type: '',
      }
    }
  },
  created() {
    this.loadKinds();
    this.getStates();
    this.getPixKeyTypes();
  },
  computed: {
    filteredSpecialties() {
      return this.specialties.filter(specialty => {
        return specialty.spec_name.toLowerCase().includes(this.searchSpec.toLowerCase())
      })
    },
  },
  async mounted() {
    KTPasswordMeter.init();
    this.initStepper();

    await new Promise(r => setTimeout(r, 500));
    this.register.prof_email = this.registerParam?.prof_email ?? '';
  },
  methods: {
    getStates() {
      http.get(`/states`).then((response) => {
        this.states = response.data;
      });
    },
    getCitiesFromState(stateId) {
      return http.get(`/states/${stateId}/cities`).then((response) => {
        this.citiesFromState = response.data;

        return response;
      });
    },
    getPixKeyTypes() {
      http.get(`/pixkeytypes`).then((response) => {
        this.pix_key_types = response.data;
      });
    },
    selectPaymentMethod(paymentMethodId) {
      let index = this.register.payment_methods.indexOf(paymentMethodId);
      if (index === -1) {
        this.register.payment_methods.push(paymentMethodId);
      } else {
        this.register.payment_methods.splice(index, 1);
      }
    },
    selectSpecialty(specialtyId) {
      let index = this.register.specialties.indexOf(specialtyId);
      if (index === -1) {
        this.register.specialties.push(specialtyId);
      } else {
        this.register.specialties.splice(index, 1);
      }
    },
    loadSpecialtiesFromKind(kindId) {
      this.register.kinds = [];
      this.register.kinds.push(kindId);

      http.get(`kinds/${kindId}/specialties`).then((response) => {
        this.specialties = response.data;
      }).catch((e) => {
      });
    },
    loadKinds() {
      http.get(`kinds`).then((response) => {
        this.kinds = response.data;
      }).catch((e) => {
      });
    },
    loadPaymentMethods() {
      http.get(`payment-methods`).then((response) => {
        this.paymentMethods = response.data;
        this.register.payment_methods = this.paymentMethods.map(method => method.id_paymeth)
      }).catch((e) => {
      });
    },
    getZipCode() {
      http.get(`zipcode/` + this.register.place_zip_code).then((response) => {
        console.log(response.data);
        this.register.place_address = response.data.address;
        this.register.place_neighborhood = response.data.neighborhood;
        this.register.id_state = response.data.state?.id_state;
        this.getCitiesFromState(this.register.id_state);
        this.register.id_city = response.data.city?.id_city;
      }).catch((e) => {
        console.log(e.response)
      });
    },
    maskPIXKey(keyPixType) {
      switch (keyPixType) {
        case 'CPF':
          this.pix_key_mask = '###.###.###-##';
          break;
        case 'CNPJ':
          this.pix_key_mask = '##.###.###/####-##';
          break;
        case 'PHONE':
          this.pix_key_mask = '(##) #####-####';
          break;
        case 'EMAIL':
          this.pix_key_mask = ''
          break;
        default:
          this.pix_key_mask = '';
          break;
      }
    },
    initStepper() {
      let element = document.querySelector("#kt_register_stepper");
      this.stepper = new KTStepper(element);

      this.stepper.on("kt.stepper.next", async (stepper) => {
        let step = stepper.getCurrentStepIndex();
        if (step === 1) {
          if (!this.register.prof_cpf || !this.register.prof_email ||
              !this.register.prof_name || !this.register.prof_phone ||
              !this.register.user_password || !this.register.user_password_confirmation ||
              !this.register.term_conditions) {

            Swal.fire({
              text: "Informe todos os dados.",
              icon: "error",
            });

            return;
          }

          if (this.register.user_password !== this.register.user_password_confirmation) {
            Swal.fire({
              text: "As senhas devem ser iguais.",
              icon: "error",
            });

            return;
          }

        } else if (step === 2) {
          if (!this.register.id_city || !this.register.id_state || !this.register.place_name) {
            Swal.fire({
              text: "Informe todos os dados.",
              icon: "error",
            });

            return;
          }
        }

        stepper.goNext();
      });
      this.stepper.on("kt.stepper.previous", (stepper) => stepper.goPrevious());
    },
    async onSubmit() {
      if (this.register.kinds.length <= 0 || this.register.specialties.length <= 0) {
        Swal.fire({
          text: "Informe a área e a especialidade.",
          icon: "error",
        });

        return;
      }

      if (!this.register.prof_council) {
        let kind = this.kinds.find((k) => k.id_kind === this.register.kinds[0]);
        const {value: profCouncil} = await Swal.fire({
          title: 'Qual o número do seu ' + kind.kind_acronym,
          input: 'text',
          inputLabel: 'Informe o seu ' + kind.kind_acronym,
          showCancelButton: false,
          allowOutsideClick: false,
          inputValue: this.register.prof_council,
          confirmButtonColor: '#009ef7',
          confirmButtonText: "Avançar <i class='bi bi-arrow-right-short text-white'></i>",
          inputValidator: (value) => {
            if (!value) {
              return 'Você precisa informar o número do seu ' + kind.kind_acronym
            }
          }
        });

        this.register.prof_council = profCouncil;
      }

      http.post(`/professionals/register`, this.register).then((response) => {
        this.$store.commit('auth/setUser', response.data.user);
        this.$store.commit('auth/setToken', response.data.token);

        this.$store.dispatch('auth/loadPlaces', response.data.user).then(() => {
          this.$emit('onRegister');
        });
      }).catch((error) => {
        let response = error.response;
        if (response?.status === 422) {
          this.errors = response.data.errors;
          let listErrors = Object.values(this.errors);

          let errorsAsString = listErrors.join(', ');
          Alert.toastError("Falha na validação dos dados. " + errorsAsString);
        } else if (response?.status === 401) {
          this.messageError = response.data;
        } else {
          this.messageError = response.data;
        }
      }).finally(() => {
        this.submitted = false;
      });
    },
  }
}
</script>

<style scoped>
.form-control-custom {
  display: flex;
}
</style>